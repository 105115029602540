<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<div v-if="satchel_loading_status&&show_satchel&&!small_frame&&!minimized" class="k-satchel-inline-overlay-scrim"></div>
	<div v-if="satchel_loading_status" v-show="show_satchel" class="k-satchel-inline-outer">
		<div class="k-satchel-control-btns" v-show="satchel_loading_status=='loaded'">
			<v-btn v-show="!minimized" color="pink accent-3" fab x-small class="k-satchel-inline-control-btn elevation-0" @click="execute_cmd('hide')"><v-icon large color="#fff" style="font-size:20px!important">fas fa-xmark</v-icon></v-btn>
			<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" v-if="!force_size" v-show="!minimized" color="#333" fab x-small class="k-satchel-inline-control-btn elevation-0" @click="toggle_size"><v-icon small color="#fff" style="font-size:16px!important">fas {{small_frame?'fa-angle-double-left':'fa-angle-double-right'}}</v-icon></v-btn></template>{{small_frame?'Expand standards view':'Move standards view to side'}}</v-tooltip>
			<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" v-if="allow_minimize" color="#333" fab x-small class="k-satchel-inline-control-btn elevation-0" @click="toggle_minimize"><v-icon small color="#fff" style="font-size:16px!important">fas {{minimized?'fa-angle-up':'fa-angle-down'}}</v-icon></v-btn></template>{{minimized?'Show standards view':'Minimize standards view'}}</v-tooltip>
			<v-tooltip top><template v-slot:activator="{on}"><v-btn v-on="on" v-show="!minimized" color="#333" fab x-small class="k-satchel-inline-control-btn elevation-0" @click="execute_cmd('open_in_new_window')"><v-icon small color="#fff" style="font-size:13px!important">fas fa-suitcase</v-icon></v-btn></template>Open in {{site_config.satchel_app_name}}</v-tooltip>
		</div>
		<iframe name="satchel_iframe" class="k-satchel-inline-iframe elevation-5" :src="iframe_url"></iframe>
		<div v-if="minimized" class="k-satchel-inline-minimized-overlay-scrim" @click="minimized=false"></div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		force_size: { type: String, required: false, default() { return '' } },	// 'small' or 'large'
		allow_minimize: { type: Boolean, required: false, default() { return true } },
	},
	data() { return {
		dialog_open: true,
		satchel_origin: '',
		satchel_loading_status: false,
		show_satchel: false,
		establish_connection_tries: 1,
		max_establish_connection_tries: 60,
		establish_connection_retry_timeout: 500,
		// total amount of time, in seconds, we will try to establish a connection: (max_establish_connection_tries * establish_connection_retry_timeout) / 1000  [e.g. 60 * 500 / 1000 == 30 seconds]
		connection_established: false,
		chooser_callback_fn: null,
		queued_load_framework: null,
		small_frame_local: false,
		minimized: false,
		// if this is specified when the chooser is shown, it will be called if the user clicks to hide the embedded satchel
		embed_hide_callback_fn: null,

		// interval time (in ms) for running the maintenance_fn
		maintenance_fn_time: 100,

		// if one or more of the following fns are specified when the chooser is shown, they will be called by the maintenance_fn, and if it returns true, the action will be executed
		// this lets us, e.g., have the chooser automatically be hidden if an editor gets closed
		hide_fn: null,
		clear_select_fn: null,

		// pm_verbose: 'show_untrusted',
		pm_verbose: '',
		// pm_verbose: true,
	}},
	computed: {
		...mapState(['site_config']),
		...mapGetters([]),
		small_frame: {
			get() {
				// parent can force a size if they wish; otherwise use value from localstorage
				if (this.force_size) return this.force_size == 'small'
				return this.small_frame_local
			},
			set(val) {
				this.small_frame_local = val
				U.local_storage_set('satchel_inline_small_frame', val)
			},
		},
		iframe_url() { 
			let url = vapp.cglt_sso_url(this.satchel_origin, '?embedded')
			console.log('iframe_url', url)
			return url

			return this.satchel_origin + '?embedded'
			// below option would sign the user in (if they are signed in to Inspire), but the session in the iframe doesn't seem to transfer to opening in a different tab
			// return `${this.satchel_origin}?embedded&remote_sessid=${U.session_id}&src_app=${this.site_config.cglt_sso_src_app}`
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		// from outside this component, code should call execute(); execute_cmd is for internal use only
		execute(cmd, data) {
			if (this.pm_verbose) console.log(sr('PMX HOST ==> execute cmd $1: $2', cmd, JSON.stringify(data)))
			return new Promise((resolve, reject)=>{
				// if the Satchel iframe hasn't finished loading...
				if (this.satchel_loading_status != 'loaded') {
					// if we haven't even started loading...
					if (!this.satchel_loading_status) {
						///////////////////////////////////////////////
						// This is where we initialize the component -- after the parent says to start doing something
						///////////////////////////////////////////////

						// set satchel_origin from the store
						this.satchel_origin = this.$store.state.site_config.satchel_origin

						// for debugging we can override this...
						// if (window.location.origin.indexOf('localhost') > -1) this.satchel_origin = 'http://localhost:6051'
						// this.satchel_origin = 'https://hcs.satchelcommons.com'
						// this.satchel_origin = 'http://localhost:6051'

						// get initial small_frame_local value from lst
						this.small_frame_local = U.local_storage_get('satchel_inline_small_frame', false)

						// start the maintenance_fn, which does things like setting the iframe location if the window size changes
						this.maintenance_fn()
						setInterval(()=>this.maintenance_fn(), 100)

						// setting satchel_loading_status to a non-falsey value renders the iframe, starting satchel loading
						this.satchel_loading_status = 'loading'

						// initialize postMessage; once loading is done, satchel_loading_status will be set to 'loaded', 
						// then pm_initialize will call execute_cms, sending through resolve and reject
						this.pm_initialize(cmd, data, resolve, reject)
						///////////////////////////////////////////////
					}

				} else {
					// else we're already loaded, so send the command
					this.execute_cmd(cmd, data, resolve, reject)
				}
			})
		}, 

		execute_cmd(cmd, data, resolve, reject) {
			// load_framework or jump_to_item (these are command aliases; they do the same thing)
			if (cmd == 'load_framework' || cmd == 'jump_to_item') {
				// save queued_load_framework so we'll know to resolve once we receive a framework_loaded msg back from satchel
				this.queued_load_framework = {
					data: data,
					resolve: resolve,
				}

				// data can specify `framework_identifier` and/or `item_identifier`
				this.pm_send('load_framework', data, null, reject)

			} else if (cmd == 'clear_selected_items') {
				// clear any selected_items that were previously sent in to be shown
				this.pm_send('clear_selected_items')

			} else if (cmd == 'chooser') {
				// data should include 'chooser_mode' (boolean)
				this.pm_send('chooser', {chooser_mode: data.chooser_mode}, null, reject)

				// if chooser_mode is false, we're closing the chooser, so satchel won't call us back; therefore resolve right away
				if (data.chooser_mode == false) {
					this.chooser_callback_fn = null
					resolve()

				} else {
					// stash resolve in chooser_callback_fn, so the message listener can call it once the user chooses something
					this.chooser_callback_fn = resolve
				}
			
			} else if (cmd == 'search') {
				this.pm_send('search', {search_terms: data.search_terms, limit_to: data.limit_to}, resolve, reject)
				if (resolve) resolve('ok')

			} else if (cmd == 'open_in_new_window') {
				// when user clicks the btn to open Satchel in a new window, send the get_current_location command; when we receive back the 'current_location' msg, we'll open it (see below)
				this.pm_send('get_current_location')
				if (resolve) resolve('ok')

			} else if (cmd == 'show') {
				this.show_satchel = true
				
				// set or clear the hide_fn and embed_hide_callback_fn here -- 
				if (data?.hide_fn) this.hide_fn = data.hide_fn
				else this.hide_fn = null

				if (data?.embed_hide_callback_fn) this.embed_hide_callback_fn = data.embed_hide_callback_fn
				else this.embed_hide_callback_fn = null

				if (resolve) resolve('ok')

			} else if (cmd == 'hide') {
				// always reset chooser mode before hiding; no need to do anything after it finishes
				this.pm_send('chooser', {chooser_mode: false})

				// if we have an embed_hide_callback_fn, call it
				if (this.embed_hide_callback_fn) this.embed_hide_callback_fn()

				// clear all maintenance fns
				this.hide_fn = null
				this.clear_select_fn = null
				this.embed_hide_callback_fn = null

				this.show_satchel = false
				if (resolve) resolve('ok')
			}
		},

		// this is the initialize fn for window A that embeds/opens window B
		// here, we establish the eventListener for postMessages, then send a message to window B establishing the connection.
		pm_initialize(cmd, cmd_data, resolve, reject) {
			// set up eventListener for postMessages
			window.addEventListener('message', (event) => {
				// event.source is the window that sent the message (the window that included the iframe)
				// event.origin is the origin that sent it; make sure we trust it
				// event.data is what was sent

				// Do we trust the sender of this message?
				if (event.origin !== this.satchel_origin) {
					console.log(`   PMX HOST    !-- message to cureum from untrusted satchel origin: trusted ${this.satchel_origin} / event ${event.origin}`, event.data)
					return
				}

				// messages should all have the form {msg: 'xxx', data: ...}
				if (typeof(event.data) != 'object' || empty(event.data.msg)) {
					console.log('   PMX HOST    !-- bad message received', event.data)
					return
				}
				let msg = event.data.msg
				let data = event.data.data

				if (msg == 'received_establish_connection') {
					// if we already finished loading, return
					if (this.satchel_loading_status == 'loaded') {
						if (this.pm_verbose) console.log('   PMX HOST    <-- received_establish_connection after connection already established')
						return
					}

					if (this.pm_verbose) console.log('   PMX HOST    <-- CONNECTION ESTABLISHED WITH SATCHEL')
					this.satchel_loading_status = 'loaded'
					this.connection_established = true
					U.loading_stop()

					// if the original execute command wasn't 'show', assume that the user wants the iframe to appear first
					// (if the original command *was* show, go ahead and execute it; then we'll go on to execute whatever was in resolve)
					if (cmd != 'show') this.show_satchel = true

					// then execute the command that sent to the original execute call (wait a tick in case we just showed)
					this.$nextTick(()=>this.execute_cmd(cmd, cmd_data, resolve, reject))
				
				///////////////////////////////////////////
				// HERE ARE THE MESSAGES THAT SATCHEL MAY SEND BACK TO US
				} else if (msg == 'framework_loaded') {
					if (this.pm_verbose) console.log('   PMX HOST    <-- framework_loaded: ' + JSON.stringify(data))
					if (this.queued_load_framework) {
						if (!this.queued_load_framework.data.framework_identifier || data.framework_identifier == this.queued_load_framework.data.framework_identifier) {
							// set or clear the clear_select_fn here, when the framework has been loaded
							if (this.queued_load_framework.data?.clear_select_fn) this.clear_select_fn = data.clear_select_fn
							else this.clear_select_fn = null

							if (this.pm_verbose) console.log('   PMX HOST    <-- resolving framework load')
							this.queued_load_framework.resolve('ok')
							this.queued_load_framework = null
						}
					}

				} else if (msg == 'item_chosen') {
					if (this.pm_verbose) console.log('   PMX HOST    <-- item_chosen: ' + JSON.stringify(data))
					vapp.show_satchel = false
					let cfitem = data
					// a callback_fn should have been specified, but check just in case
					if (this.chooser_callback_fn) {
						this.chooser_callback_fn(cfitem)
						this.chooser_callback_fn = null
						// note that the callback fn should call execute('hide')
					}

				} else if (msg == 'current_location') {
					if (this.pm_verbose) console.log('   PMX HOST    <-- current_location: ' + JSON.stringify(data))
					// the currently-showing url in the iframe will be returned; open via fn in App.vue, which will use the cglt_sso method if available
					vapp.open_satchel_in_new_window(data.href)

					// more messages here...
				} else {
					if (this.pm_verbose) console.log('   PMX HOST    !-- received unprocessed message: ', msg, data)
				}
			}, false);

			// show loader, then start trying to establish the connection
			U.loading_start()
			setTimeout(x=>{ this.pm_establish_connection() }, 100)
		},

		pm_establish_connection() {
			// window B may not be ready to receive messages right away, so keep sending this message until it succeeds, or until we exceed max_establish_connection_tries
			if (!this.connection_established) {
				if (this.establish_connection_tries > this.max_establish_connection_tries) {
					console.log('   PMX HOST    !-- giving up on establishing connection')
					U.loading_stop()
					vapp.$alert('The connection to Satchel could not be opened.')
					return
				}
				++this.establish_connection_tries
				if (this.pm_verbose) console.log('   PMX HOST    --> queuing establish_connection message (' + this.establish_connection_tries + ')')
				this.pm_send('establish_connection')

				setTimeout(x=>{ this.pm_establish_connection() }, this.establish_connection_retry_timeout)
			}
		},

		pm_send(msg, data, resolve, reject) {
			if (this.pm_verbose) console.log('   PMX HOST    --> pm_send: ' + msg)

			// queue a message to be sent; second param specifies what the origin of the target window must be for the event to be dispatched
			try {
				if (msg == 'establish_connection' && this.establish_connection_tries == 1) {
					// in console, note that we may get an error message here
					console.log('   PMX HOST    --> NOTE: “Failed to execute \'postMessage\' on \'DOMWindow\'” message may occur on first `establish_connection` message try')
				}
				window.satchel_iframe.postMessage({msg: msg, data: data}, this.satchel_origin)
				if (resolve) resolve('ok')

			} catch(e) {
				console.log('   PMX HOST    !-- pm_send error caught: ', e)
				if (reject) reject(e)
			}
		},

		toggle_size(evt) {
			this.small_frame = !this.small_frame
			this.maintenance_fn()
			if (!empty(evt) && !empty(evt.target)) $(evt.target).closest('button').blur()
		},

		toggle_minimize(evt) {
			this.minimized = !this.minimized
			this.maintenance_fn()
			if (!empty(evt) && !empty(evt.target)) $(evt.target).closest('button').blur()
		},

		// this fn constantly runs while the satchel component is loaded, and does "maintenance" things like setting the iframe wrapper boundaries
		maintenance_fn() {
			// if hide_fn is set, check it, and if it succeeds, hide the satchel component
			if (this.hide_fn && this.hide_fn()) {
				this.execute_cmd('hide')
				return
			}

			// if clear_select_fn is set, check it, and if it succeeds, clear the selected items
			if (this.clear_select_fn && this.clear_select_fn()) {
				this.execute_cmd('clear_selected_items')
				return
			}

			// set width and height of iframe, depending on settings for minimized and small_frame
			let ww = $(window).width()
			let wh = $(window).height()

			if (!this.satchel_loading_status || !this.show_satchel) {
				// reset v-application width if necessary
				$('.v-application').css('width', 'auto')
				$('.v-dialog__content--active').css('width', '100%')
				return
			}

			if (this.minimized) {
				let sw = Math.round(ww / 3)
				if (sw > 500) sw = 500

				$('.k-satchel-inline-outer').css({
					'width': sw + 'px',
					'left': 'auto',
					'right': '0px',
					'height': '46px',
					'top': (wh - 46)+'px',
				})
				$('.k-satchel-inline-minimized-overlay-scrim').css({
					'width': sw + 'px',
				})
				$('.k-satchel-inline-iframe').css({
					'border-radius': '0',
				})
				$('.k-satchel-control-btns').css({
					'border-radius': '12px 0 0 12px',
				})

				$('.v-application').css('width', 'auto')
				$('.v-dialog__content--active').css('width', '100%')
				return
			}

			// TODO: if width is too small, just show over everything else?

			if (this.small_frame) {
				let sw = Math.round(ww / 3)
				if (sw > 600) sw = 600
				if (sw < 448) sw = 448

				$('.k-satchel-inline-outer').css({
					'width': sw + 'px',
					'left': 'auto',
					'right': '0px',
					'height': (wh+2) + 'px',
					'top': '-1px',
				})
				$('.k-satchel-inline-iframe').css({
					'border-radius': '0',
				})
				$('.k-satchel-control-btns').css({
					'border-radius': '0 0 0 12px',
				})

				// set v-application, and all dialogs, to the left-over width
				let app_width = (ww - sw)+'px'
				$('.v-application').css('width', app_width)
				$('.v-dialog__content--active').css('width', app_width)

			} else {
				let sw = ww - 100
				let sh = wh - 40
				if (sw > 1200) sw = 1200

				$('.k-satchel-inline-outer').css({
					'width': sw + 'px',
					'left': sr('calc(50vw - $1)', Math.round(sw/2) + 'px'),
					'right': 'auto',
					'height': sh + 'px',
					'top': '20px',
				})
				$('.k-satchel-inline-iframe').css({
					'border-radius': '0 12px 12px 12px',
				})
				$('.k-satchel-control-btns').css({
					'border-radius': '12px 0 0 12px',
				})

				$('.v-application').css('width', 'auto')
				$('.v-dialog__content--active').css('width', '100%')
			}
		},
	}
}
</script>

<style lang="scss">
.k-satchel-inline-outer {
	position:fixed;
	z-index:10000;
	border-radius:12px;
	transition: top .2s, height .2s;
	// top/left/width/top/height will be set by maintenance_fn
}

.k-satchel-inline-iframe {
	width:100%;
	height:100%;
	border-radius:12px;
	border:1px solid #999;
	background-color:#999;
}

.k-satchel-control-btns {
	position:absolute;
	left:-42px;
	width:42px;
	background-color:rgba(153,153,153,0.8);
	border-radius:0 0 0 12px;
	padding:6px 6px 6px 8px;
	min-height:64px;
}

.k-satchel-inline-control-btn {
	// position:absolute;
	// right:-14px;
	// top:-10px;
	width:27px!important;
	height:27px!important;
	margin:3px 0;
	border:1px solid #999!important;
}

.k-satchel-inline-overlay-scrim {
	position:fixed;
	z-index:9999;
	left:0;
	top:0;
	width:100vw;
	height:100vh;
	opacity:0.8; 
	background-color:#000;
}

.k-satchel-inline-minimized-overlay-scrim {
	position:absolute;
	right:0;
	bottom:0;
	height:46px;
	background-color:transparent;
}
</style>
